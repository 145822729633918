// import {tns} from './tiny-slider.js';
import { tns } from 'tiny-slider/src/tiny-slider';

window.addEventListener('DOMContentLoaded', () => {
    const sliders = document.querySelectorAll('.galeria-imagens');
    sliders.forEach((item, index) => {
      const slider = tns({
        container: item,
        items: 1,
        center: true,
        loop: true,
        autoplay: false,
        controlsPosition: 'bottom',
        nav: false,
        mouseDrag: true,
        controlsContainer: `#customize-controls-${item.getAttribute('data-nav')}`,
        responsive: {
          1024: {
            gutter: 125,
            items: 2,
          },
          1280: {
            gutter: 0,
            items: 2,
          }, 
          1536: {
            gutter: 60,
            items: 4,
          },                    
        },
      });
      const info = slider.getInfo();
      const indexCurr = info.index;
      const legendaSource = info.slideItems[indexCurr].querySelector('.legenda-source');
      const legendas =  document.querySelectorAll('.legenda')
      legendas.forEach((legenda) => {
        legenda.innerHTML = legendaSource.innerHTML;
      });

      slider.events.on('indexChanged', () => {
        const info = slider.getInfo();
        const indexPrev = info.indexCached;
        const indexCurr = info.index;
        // console.log(indexPrev, indexCurr);
        // console.log(info.slideItems[indexCurr]);
        const legendaSource = info.slideItems[indexCurr].querySelector('.legenda-source');
        const legendas =  document.querySelectorAll('.legenda')
        legendas.forEach((legenda) => {
          legenda.innerHTML = legendaSource.innerHTML;
        });
      });      
    });
});